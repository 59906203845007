<template>
  <div>
    <section class="content-header">
      <h1>
        App notifications
        <small>List of all uploaded </small>
      </h1>
    </section>
    <div
      class="row"
      style="
        background-color: transparent;
        padding: 15px 10px;
        border-radius: 5px;
      "
    >
      <div class="col-12">
        <div class="box" style="width: 100% !important">
          <div class="box-header">
            <h3 class="box-title">Notification Details</h3>
          </div>
          <div class="box-body table-responsive no-padding">
            <table class="table table-hover">
              <tbody>
                <tr>
                  <td>Audience</td>
                  <td>{{ total.delivered }}</td>
                </tr>
                <tr>
                  <td>Message Title</td>
                  <td>{{ notification.title }}</td>
                </tr>
                <tr>
                  <td>Message Content</td>
                  <td>{{ notification.description }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="small-box bg-green">
          <div class="inner">
            <h3 v-if="total.delivered != undefined">
              {{ total.delivered }}
            </h3>
            <h3 v-else>-</h3>

            <p>Delivered</p>
          </div>
          <div class="icon">
            <i class="mdi mdi-check"></i>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="small-box bg-aqua">
          <div class="inner">
            <h3>
              {{ rate }}
              <sup style="font-size: 20px">%</sup>
              <sub style="font-size: 20px" v-if="clicks.clicks != undefined"
                >({{ clicks.clicks }} unique)</sub
              >
              <sub v-else> -</sub>
            </h3>

            <p>Click-Through Rate</p>
          </div>
          <div class="icon">
            <i class="mdi mdi-chart-areaspline"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">Delivery Statistics</h3>
          </div>
          <div class="box-body">
            <div class="chart">
              <apexchart
                width="380"
                type="pie"
                :options="deliveryChartOptions"
                :series="deliveryStats"
              ></apexchart>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">Platform Statistics</h3>
          </div>
          <div class="box-body">
            <div class="chart">
              <apexchart
                width="363"
                type="pie"
                :options="chartOptions"
                :series="platformStats"
              ></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.skin-blue-light .content-header {
  background: transparent;
}
.content-header {
  position: relative;
  padding: 15px 15px 0 15px;
}
.content-header > h1 {
  margin: 0;
  font-size: 24px;
}
.content-header > h1 > small {
  font-size: 15px;
  display: inline-block;
  padding-left: 4px;
  font-weight: 300;
}
.box-header {
  color: #444;
  display: block;
  padding: 10px;
  position: relative;
}
.table th,
.table td {
  font-size: 15px !important;
}
.small-box > .inner {
  padding: 10px;
}
.small-box {
  border-radius: 2px;
  position: relative;
  display: block;
  margin-bottom: 20px;
  box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
  /*margin-right: 20px;*/
  width: 95%;
}
.bg-green,
.callout.callout-success,
.alert-success,
.label-success,
.modal-success .modal-body {
  background-color: #39b550 !important;
}
.bg-aqua,
.callout.callout-info,
.alert-info,
.label-info,
.modal-info .modal-body {
  background-color: #00c0ef !important;
}
.bg-red,
.bg-yellow,
.bg-aqua,
.bg-blue,
.bg-light-blue,
.bg-green,
.bg-navy,
.bg-teal,
.bg-olive,
.bg-lime,
.bg-orange,
.bg-fuchsia,
.bg-purple,
.bg-maroon,
.bg-black,
.bg-red-active,
.bg-yellow-active,
.bg-aqua-active,
.bg-blue-active,
.bg-light-blue-active,
.bg-green-active,
.bg-navy-active,
.bg-teal-active,
.bg-olive-active,
.bg-lime-active,
.bg-orange-active,
.bg-fuchsia-active,
.bg-purple-active,
.bg-maroon-active,
.bg-black-active,
.callout.callout-danger,
.callout.callout-warning,
.callout.callout-info,
.callout.callout-success,
.alert-success,
.alert-danger,
.alert-error,
.alert-warning,
.alert-info,
.label-danger,
.label-info,
.label-warning,
.label-primary,
.label-success,
.modal-primary .modal-body,
.modal-primary .modal-header,
.modal-primary .modal-footer,
.modal-warning .modal-body,
.modal-warning .modal-header,
.modal-warning .modal-footer,
.modal-info .modal-body,
.modal-info .modal-header,
.modal-info .modal-footer,
.modal-success .modal-body,
.modal-success .modal-header,
.modal-success .modal-footer,
.modal-danger .modal-body,
.modal-danger .modal-header,
.modal-danger .modal-footer {
  color: #fff !important;
}
.small-box h3 {
  font-size: 38px;
  font-weight: bold;
  margin: 0 0 10px 0;
  white-space: nowrap;
  padding: 0;
}
.small-box h3,
.small-box p {
  z-index: 5;
}
.small-box .icon {
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  position: absolute;
  top: -10px;
  right: 10px;
  z-index: 0;
  font-size: 90px;
  color: rgba(0, 0, 0, 0.15);
}
.box.box-primary {
  border-top-color: #3c8dbc;
}

.box {
  position: relative;
  border-radius: 3px;
  background: #ffffff;
  border-top: 3px solid #d2d6de;
  margin-bottom: 20px;
  width: 95%;
  box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
}
.box-header.with-border {
  border-bottom: 1px solid #f4f4f4;
}
.box-header {
  color: #444;
  display: block;
  padding: 10px;
  position: relative;
}
.box-body {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 10px;
}
.box-header:before,
.box-body:before,
.box-footer:before,
.box-header:after,
.box-body:after,
.box-footer:after {
  content: " ";
  display: table;
}
.box-header > .fa,
.box-header > .glyphicon,
.box-header > .ion,
.box-header .box-title {
  display: inline-block;
  font-size: 18px;
  margin: 0;
  line-height: 1;
}
.apexcharts-canvas {
  margin: auto;
}
</style>
<script>
// import '@/plugins/apexcharts'
var numeral = require("numeral");
export default {
  name: "details",
  mixins: [],
  props: {
    cassielid: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    rawHtml: "",
    total: [],
    notification: {},
    clicks: [],
    rate: 0,
    chartOptions: {
      chart: {
        id: "vuechart-platform",
      },
      labels: [],
      colors: ["rgb(27, 129, 212)", "rgb(90, 172, 240)"],
    },
    deliveryChartOptions: {
      chart: {
        id: "vuechart-delivery",
      },
      labels: [],
      colors: [
        "rgb(0,206,209)",
        "rgb(59, 182, 116)",
        "rgb(165,42,42)",
        "rgb(229, 75, 77)",
        "rgb(255,204,153)",
        "rgb(64,130,109)",
      ],
    },
    platformStats: [],
    deliveryStats: [],
  }),
  components: {},
  mounted: function () {
    this.previewLink = process.env.VUE_APP_PREVIEW_BASE_URL;
    // const baseFormDetailsUrl = this.$apiList.baseFormDetailsUrl;

    let formConfigurationUrl =
      process.env.VUE_APP_CMS_BACKEND_BASE_URL +
      "/cassiel-backend-jamapp/notificationdetails";
    if (this.$route.query.cassielId != undefined) {
      this.cassielid = this.$route.query.cassielId;
    }
    const token = this.$store.getters["auth/getToken"];
    let headers = {};
    if (token) {
      headers["X-AUTH-TOKEN"] = token;
    }

    var bodyFormData = new FormData();
    bodyFormData.append("cassielId", this.cassielid);

    this.$http({
      url: formConfigurationUrl,
      method: "POST",
      data: bodyFormData,
      headers: headers,
    }).then((response) => {
      this.notification = response.data.notification;
      this.total = response.data.total;
      this.clicks = response.data.clicks;
      this.rawHtml = response.data;
      this.rate = this.number_formated(response.data.clicks.rate);
      if (response.data.stats != undefined) {
        const entries = Object.entries(response.data.stats);
        for (var i = 0; i < entries.length; i++) {
          this.platformStats.push(entries[i][1].delivered);
          this.chartOptions.labels.push(entries[i][0]);
        }
      }
      if (response.data.total != undefined) {
        const entries = Object.entries(response.data.total);
        for (var j = 0; j < entries.length; j++) {
          this.deliveryStats.push(entries[j][1]);
          this.deliveryChartOptions.labels.push(entries[j][0]);
        }
      }
      this.$nextTick(function () {});
      this.is_data_fetched = true;
    });
  },
  methods: {
    number_formated(value) {
      return numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
    },
  },
};
</script>
